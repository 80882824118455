<template>
  <div>
    <sdPageHeader :title="isEditing && headline ? headline : 'Add Campaign'">
      <template v-if="isEditing" v-slot:buttons>
        <div class="page-header-actions">
          <sdButton @click="onRemovePress" size="small" type="primary">
            <sdFeatherIcons type="trash-2" size="14" />
            Remove Campaign
          </sdButton>
          <sdButton @click="openFrontPage" size="small" type="white">
            <sdFeatherIcons type="eye" size="14" style="margin-top: 1px" />
            Preview Campaign
          </sdButton>
        </div>
      </template>
    </sdPageHeader>
    <GeneralForm
      :formData="form"
      :initialData="initialData"
      :otherFormData="otherForm"
      :isEditing="isEditing"
    >
      <template v-slot:content-setting>
        <div class="create-account-form">
          <div>
            <a-row :gutter="15">
              <a-col :lg="12" :xs="24">
                <sdHeading as="h4">Step 1 Details</sdHeading>
                <a-form-item name="step1_title" label="Title">
                  <a-input
                    v-model:value="form.step1_title"
                    placeholder="Enter Title"
                  />
                </a-form-item>
                <a-form-item name="step1_description" label="Description">
                  <a-textarea
                    v-model:value="form.step1_description"
                    :rows="4"
                    placeholder="Enter description"
                  />
                </a-form-item>
                <a-form-item name="step1_button_text" label="Button Text">
                  <a-input
                    v-model:value="form.step1_button_text"
                    placeholder="Enter button text"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :xs="24">
                <sdHeading as="h4">Step 2 Details</sdHeading>
                <a-form-item name="step2_title" label="Title">
                  <a-input
                    v-model:value="form.step2_title"
                    placeholder="Enter Title"
                  />
                </a-form-item>
                <a-form-item name="step2_description" label="Description">
                  <a-textarea
                    v-model:value="form.step2_description"
                    :rows="4"
                    placeholder="Enter description"
                  />
                </a-form-item>
              </a-col>
              <a-col style="margin-top: 30px" :lg="12" :xs="24">
                <sdHeading as="h4">Success details</sdHeading>
                <a-form-item name="success_title" label="Title">
                  <a-input
                    v-model:value="form.success_title"
                    placeholder="Enter Title"
                  />
                </a-form-item>
                <a-form-item name="success_btn_title" label="Button Title">
                  <a-input
                    v-model:value="form.success_btn_title"
                    placeholder="Enter success button title"
                  />
                </a-form-item>
                <a-form-item name="success_url" label="Button URL">
                  <a-input
                    type="url"
                    v-model:value="form.success_url"
                    placeholder="Enter success button url"
                  />
                  <small
                    >[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small
                  >
                </a-form-item>
                <a-form-item name="success_description" label="Description">
                  <a-textarea
                    v-model:value="form.success_description"
                    :rows="4"
                    placeholder="Enter description"
                  />
                </a-form-item>
              </a-col>
              <a-col style="margin-top: 30px" :lg="12" :xs="24">
                <sdHeading as="h4">Failure details</sdHeading>
                <a-form-item name="fail_title" label="Title">
                  <a-input
                    v-model:value="form.fail_title"
                    placeholder="Enter Title"
                  />
                </a-form-item>
                <a-form-item name="fail_btn_title" label="Button Title">
                  <a-input
                    v-model:value="form.fail_btn_title"
                    placeholder="Enter failure button title"
                  />
                </a-form-item>
                <a-form-item name="fail_url" label="Button URL">
                  <a-input
                    type="url"
                    v-model:value="form.fail_url"
                    placeholder="Enter failure button url"
                  />
                  <small
                    >[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small
                  >
                </a-form-item>
                <a-form-item name="fail_description" label="Description">
                  <a-textarea
                    v-model:value="form.fail_description"
                    :rows="4"
                    placeholder="Enter description"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <sdHeading as="h4">Card Images</sdHeading>
            <a-row :gutter="15">
              <a-col :lg="6" :xs="24">
                <a-form-item name="card_front" label="Card Step 1 Image">
                  <a-input
                    type="file"
                    id="card-front-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'card_front')"
                  />
                </a-form-item>
                <div v-if="card_front_url" class="img-thumb-cls">
                  <img alt="Card Front Image" :src="card_front_url" />
                </div>
              </a-col>
              <a-col :lg="6" :xs="24">
                <a-form-item name="card_step2" label="Card Step 2 Image">
                  <a-input
                    type="file"
                    id="card-back-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'card_step2')"
                  />
                </a-form-item>
                <div v-if="card_step2_url" class="img-thumb-cls">
                  <img alt="Card Step2 Image" :src="card_step2_url" />
                </div>
              </a-col>
              <a-col :lg="6" :xs="24">
                <a-form-item name="card_winning" label="Winning Image">
                  <a-input
                    type="file"
                    id="card-back-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'card_winning')"
                  />
                </a-form-item>
                <div v-if="card_winning_url" class="img-thumb-cls">
                  <img alt="Card Winning Image" :src="card_winning_url" />
                </div>
              </a-col>
              <a-col :lg="6" :xs="24">
                <a-form-item name="card_back" label="Losing Image">
                  <a-input
                    type="file"
                    id="card-back-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'card_back')"
                  />
                </a-form-item>
                <div v-if="card_back_url" class="img-thumb-cls">
                  <img alt="Card Back Image" :src="card_back_url" />
                </div>
              </a-col>
              <a-col :lg="8" :xs="24">
                <a-form-item name="loser_popup_image" label="Loser Popup Image">
                  <a-input
                    type="file"
                    id="card-back-file"
                    accept="image/*"
                    v-on:change="onImageChange($event, 'loser_popup_image')"
                  />
                </a-form-item>
                <div v-if="loser_popup_url" class="img-thumb-cls">
                  <img alt="Loser Popup Image" :src="loser_popup_url" />
                </div>
              </a-col>
              <a-col :lg="8" :xs="24">
                <a-form-item name="bg_image" label="Background Image">
                  <a-input
                    type="file"
                    accept="image/*"
                    id="bg-image-file"
                    v-on:change="onImageChange($event, 'bg_image')"
                  />
                </a-form-item>
                <div v-if="bg_image_url" class="img-thumb-cls">
                  <img alt="Background Image" :src="bg_image_url" />
                </div>
              </a-col>
              <a-col :lg="8" :xs="24">
                <a-form-item
                  name="scratch_cursor_image"
                  label="Scratch Cursor Image"
                >
                  <a-input
                    type="file"
                    accept="image/*"
                    id="bg-image-file"
                    v-on:change="onScratchCursorImage"
                  />
                </a-form-item>
                <div v-if="scratch_cursor_image_url" class="img-thumb-cls">
                  <img
                    alt="Scratch Cursor Image"
                    :src="scratch_cursor_image_url"
                  />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="15">
              <a-col :lg="24" :xs="24">
                <sdHeading as="h4">Prize Block</sdHeading>
                <a-row :gutter="25" style="margin-top: 15px">
                  <a-col :lg="12" :xs="24">
                    <a-checkbox
                      name="prize_popup_status"
                      v-model:checked="form.prize_popup_status"
                    >
                      <strong>Active</strong>
                    </a-checkbox>
                  </a-col>
                </a-row>
                <a-row :gutter="25" style="margin-bottom: 0">
                  <a-col :lg="12" :xs="24">
                    <a-form-item name="prize_block_title" label="Popup Title">
                      <a-input
                        v-model:value="form.prize_block_title"
                        placeholder="Enter Title"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :lg="12" :xs="24">
                    <a-form-item
                      name="prize_button_text"
                      label="Prize Button Text"
                    >
                      <a-input
                        v-model:value="form.prize_button_text"
                        placeholder="Enter button text"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
      </template>
      <template v-slot:winners-prizes>
        <a-row :gutter="15" style="margin-bottom: 0">
          <a-col style="margin-bottom: 26px" :lg="12" :xs="24">
            <a-form-item name="scratch_percentage" label="Scratch Percentage">
              <a-input
                type="number"
                v-model:value="form.scratch_percentage"
                placeholder="Enter scratch percentage"
              />
            </a-form-item>
          </a-col>
          <a-col style="margin-bottom: 26px" :lg="12" :xs="24">
            <a-form-item name="winning_percentage" label="Winning Percentage">
              <a-input
                type="number"
                v-model:value="form.winning_percentage"
                placeholder="Enter winning percentage"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="15"
            v-for="(spnr, index) in form.scratch_prices"
            :key="`row_${index}`"
        >
          <a-col :lg="10" :xs="24" class="custom_select_selector">
            <a-form-item :name="['scratch_prices', index, 'prize']" label="Prize">
              <a-select
                @change="(e) => selectPrize(e, index)"
                show-search
                placeholder="Select prize"
                option-filter-prop="label"
                v-model:value="spnr.prize"
              >
                <a-select-option :value="0">No prize</a-select-option>
                <a-select-option
                  v-for="prize of prizes"
                  :key="prize.id"
                  v-bind:value="prize.id"
                  >{{ prize.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col style="margin-bottom: 26px" :lg="10" :xs="24">
            <a-form-item :name="['scratch_prices', index, 'weight']" label="Weight">
              <a-input
                type="number"
                v-model:value="spnr.weight"
                placeholder="Enter prize weight"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="4" :xs="24" class="custom_more_btn" style="margin-top:27px;">
            <a-button
              type="dashed"
              class="dynamic-delete-button"
              style="width: 95%"
              v-if="index > 0"
              @click="removeScratchPriceBlock(index)"
            >
              <MinusOutlined /> Remove Block
            </a-button>
            <a-button
              type="dashed"
              style="width: 95%"
              v-else-if="index == 0"
              @click="addScratchPriceBlock"
            >
              <PlusOutlined /> Add Block
            </a-button>
          </a-col>
        </a-row>
      </template>
    </GeneralForm>
    <a-modal
      v-model:visible="deleteConfirmVisible"
      title="Campaign remove confirmation"
      :confirm-loading="isDeleting"
      :centered="true"
      ok-text="Remove"
      cancel-text="Cancel"
      @ok="handleDeleteModal"
      :ok-button-props="okButtonProps"
    >
      <p>Are you sure you want to delete this campaign?</p>
    </a-modal>
  </div>
</template>

<script>
import _ from "lodash";

import GeneralForm from "@/components/generalComponent/portal/campaignForm.vue";

import { getImageHeightWidth } from "@/services/helperFunctions.js";
import { theme } from "@/config/theme/themeVariables";
import Notification from "@/services/Notification";
import { API } from "@/services/api";
export default {
  name: "SetupCampaign",
  components: {
    GeneralForm,
  },
  data() {
    return {
      initialData: {},
      form: {
        winning_percentage: "",
        scratch_percentage: "",
        prize: "",
        step1_title: "",
        step1_button_text: "",
        step1_description: "",
        step2_title: "",
        step2_description: "",
        success_title: "",
        success_url: "",
        success_btn_title: "",
        success_description: "",
        fail_title: "",
        fail_url: "",
        fail_btn_title: "",
        fail_description: "",
        prize_popup_status: true,
        prize_block_title: "",
        prize_button_text: "",
        card_front: null,
        card_back: null,
        card_step2: null,
        card_winning: null,
        loser_popup_image: null,
        bg_image: null,
        scratch_cursor_image: null,
        scratch_prices: [],
      },
      otherForm: {
        meta_image_url: null,
        campaign_image_url: null,
        selectedOs: [],
        selectedDevice: [],
        selectedBrowser: [],
        selectedCountry: [],
        scriptSelectedCountry: [],
        scriptSelectedDevice: [],
      },
      prizes: [],
      slug: "",
      remove_prizes: [],
      headline: "",
      bg_image_url: "",
      scratch_cursor_image_url: "",
      card_back_url: "",
      card_front_url: "",
      card_step2_url: "",
      card_winning_url: "",
      loser_popup_url: "",
      isDeleting: false,
      deleteConfirmVisible: false,
      okButtonProps: {
        style: {
          backgroundColor: theme["primary-color"],
          borderWidth: 0,
        },
      },
    };
  },
  computed: {
    gameData: function () {
      return this.$store.getters.gameData;
    },
    themeData: function () {
      return this.$store.getters.themeData;
    },
    countries: function () {
      return this.$store.getters.countries;
    },
  },
  methods: {
    onImageChange(e, type) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      if (type == "bg_image") {
        this.bg_image_url = blobURL;
        this.form.bg_image = file;
        return null;
      } else if(type == "card_front") {
        this.card_front_url = blobURL;
        this.form.card_front = file;
        return null;
      }
      const IMAGE_HEIGHT = 300;
      const IMAGE_WIDTH = 300;
      const th = this;

      const img = new Image();
      img.src = blobURL;
      img.height = IMAGE_HEIGHT;
      img.width = IMAGE_WIDTH;
      img.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.height = IMAGE_HEIGHT;
        canvas.width = IMAGE_WIDTH;
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.fillStyle = "#FFFFFF";
        canvasCtx.fillRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvasCtx.drawImage(img, 0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvas.toBlob(
          (blob) => {
            const newFile = new File([blob], file.name, { type: "image/png" });
            if (type === "card_back") {
              (th.form.card_back = newFile),
                (th.card_back_url = URL.createObjectURL(newFile));
            } else if (type === "card_step2") {
              (th.form.card_step2 = newFile),
                (th.card_step2_url = URL.createObjectURL(newFile));
            } else if (type === "card_winning") {
              (th.form.card_winning = newFile),
                (th.card_winning_url = URL.createObjectURL(newFile));
            } else if (type === "loser_popup_image") {
              (th.form.loser_popup_image = newFile),
                (th.loser_popup_url = URL.createObjectURL(newFile));
            }
          },
          "image/png",
          1
        );
      };
    },
    async onScratchCursorImage(e) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      const { height = 0, width = 0 } = await getImageHeightWidth(blobURL);
      const IMAGE_HEIGHT = height > 128 ? 128 : height;
      const IMAGE_WIDTH = width > 128 ? 128 : width;
      const th = this;
      const img = new Image();
      img.src = blobURL;
      img.height = IMAGE_HEIGHT;
      img.width = IMAGE_WIDTH;
      img.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.height = IMAGE_HEIGHT;
        canvas.width = IMAGE_WIDTH;
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.fillStyle = "transparent";
        canvasCtx.fillRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvasCtx.drawImage(img, 0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvas.toBlob(
          (blob) => {
            const newFile = new File([blob], file.name, { type: "image/png" });
            (th.form.scratch_cursor_image = newFile),
              (th.scratch_cursor_image_url = URL.createObjectURL(newFile));
          },
          "image/png",
          1
        );
      };
    },
    onRemovePress() {
      this.deleteConfirmVisible = true;
    },
    async handleDeleteModal() {
      try {
        this.isDeleting = true;
        const delRes = await API.deleteCampaign({ id: this.$route.params.id });
        const { status, message } = delRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.$router.go(-1);
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.isDeleting = false;
        this.deleteConfirmVisible = false;
      }
    },
    async getDetails() {
      try {
        const detRes = await API.getCampaignDetails({
          params: { id: this.$route.params.id, page: "edit-campaign" },
        });
        const { data: details, status, message } = detRes.data;
        const newFormData = {};
        const newInitialData = {};

        if (status) {
          for (const [key, value] of Object.entries(details)) {
            if (key && value) {
              if (key.includes("flag")) {
                if (!_.has(this.form, key)) {
                  newInitialData[key] = value && !!JSON.parse(value);
                } else {
                  newFormData[key] = value && !!JSON.parse(value);
                }
              } else if (key == "scratch_prices") {
                if (value.length > 0) {
                  if (_.isString(value)) {
                    newFormData[key] = JSON.parse(value);
                  } else {
                    newFormData[key] = value;
                  }
                }
              } else {
                if (!_.has(this.form, key)) {
                  newInitialData[key] = this.getValue(value);
                } else {
                  newFormData[key] = this.getValue(value);
                }
              }
            }
          }
          this.initialData = {
            ...this.initialData,
            ...newInitialData,
          };
          this.form = {
            ...this.form,
            ...newFormData,
          };
          const {
            name = "",
            slug = "",
            bg_image = "",
            card_back = "",
            card_front = "",
            card_step2 = "",
            card_winning = "",
            loser_popup_image = "",
            scratch_cursor_image = "",
            os = [],
            device = [],
            browser = [],
            country = "",
            meta_image = null,
            campaign_image = null,
            script_country = [],
            script_device = [],
            prize_id = "0",
          } = details;
          this.headline = `Edit Campaign : ${name}`;
          this.slug = slug;
          this.otherForm.meta_image_url = meta_image;
          this.otherForm.campaign_image_url = campaign_image;
          this.bg_image_url = bg_image;
          this.card_back_url = card_back;
          this.card_front_url = card_front;
          this.card_step2_url = card_step2;
          this.card_winning_url = card_winning;
          this.scratch_cursor_image_url = scratch_cursor_image;
          this.loser_popup_url = loser_popup_image;
          this.form.prize = prize_id;
          if (!_.isEmpty(os)) {
            this.otherForm.selectedOs = os;
          }
          if (!_.isEmpty(device)) {
            this.otherForm.selectedDevice = device;
          }
          if (!_.isEmpty(browser)) {
            this.otherForm.selectedBrowser = browser;
          }
          if (!_.isEmpty(country)) {
            this.otherForm.selectedCountry = _.map(
              this.countries,
              (c) => country.includes(c.value) && c.value
            ).filter((c) => c);
          }
          if (!_.isEmpty(script_country)) {
            this.otherForm.scriptSelectedCountry = script_country;
          }
          if (!_.isEmpty(script_device)) {
            this.otherForm.scriptSelectedDevice = script_device;
          }
        } else {
          if (message) {
            Notification.show(message, { type: "error", title: "Error" });
          }
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async openFrontPage() {

      await this.getDetails() 

      const encrypt_id = this.$route.params.id;
      const campaign_domain = this.initialData.campaign_domain;
      const slug = this.slug;

      if (process.env.NODE_ENV == "development") {
        const routeData = this.$router.resolve({
          path: `/${slug}`,
        });
        window.open(routeData.href, "_blank");
      } else {
        if (!campaign_domain && encrypt_id) {
          window.open(
            `${process.env.VUE_APP_DOMAIN}${slug}`,
            "_blank"
          );
        } else {
          window.open(`${campaign_domain}${slug}`, "_blank");
        }
      }
    },
    getValue(value) {
      if (value == null) {
        return "";
      } else if (_.isString(value)) {
        if (value == "true" || value == "false") {
          return JSON.parse(value);
        }
        return value;
      } else if (_.isNumber(value) || _.isArray(value)) {
        return value;
      } else {
        return JSON.stringify(value);
      }
    },
    addScript() {
      this.scriptId = "";
      this.addScriptVisible = true;
    },
    cancelScriptModal() {
      this.script_name = "";
      this.script_position = "";
      this.script = "";
    },
    async handleScriptAddModal() {
      try {
        const campaignId = this.$route.params.id;

        const param = {
          id: this.scriptId,
          campaign_id: campaignId,
          name: this.script_name,
          position: this.script_position,
          script: this.script,
        };
        this.campaignId = campaignId;

        const updateRes = await API.commitCampaignScript(param);
        const { status, message } = updateRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.addScriptVisible = false;
          this.script_name = "";
          this.script_position = "";
          this.script = "";
          this.getScripts();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async getScripts() {
      try {
        const detRes = await API.getCampaignsScripts({
          params: { campaign_id: this.$route.params.id },
        });
        const { data, status, message } = detRes.data;
        if (status) {
          this.scriptList = data;
        } else {
          if (message) {
            Notification.show(message, { type: "error", title: "Error" });
          }
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    onServiceupdate(record) {
      this.scriptId = record.encrypt_id;
      this.script_name = record.name;
      this.script_position = record.position;
      this.script = record.script;
      this.addScriptVisible = true;
    },
    onServiceRemove(id) {
      this.scriptId = id;
      this.deleteScriptConfirmVisible = true;
    },
    async handleScriptDeleteModal() {
      try {
        const delRes = await API.deleteCampaignScripts({ id: this.scriptId });
        const { status, message } = delRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.deleteScriptConfirmVisible = false;
          this.getScripts();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.deleteScriptConfirmVisible = false;
      }
    },
    async getPrizes() {
      try {
        const datRes = await API.getPrizes();
        const { data: sData, status, message } = datRes.data;
        if (!status && message) {
          Notification.show(message, { type: "error", title: "Error" });
        } else {
          this.prizes = sData.prizes;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.fetching = false;
      }
    },
    async scratchPriceCount(status = true) {
        if(status) {
          this.form.scratch_prices.push({prize: "",weight: ""});
        }
    },
    async addScratchPriceBlock() {
      await this.scratchPriceCount();
    },
    async selectPrize(e, index) {

      this.form.scratch_prices[index]['prize'] = 0;

      let prize_status = true;

        for (let i = 0; i < this.form.scratch_prices.length; i++) {

          let scratch_i = await this.form.scratch_prices[i];

          if(e == scratch_i.prize  && scratch_i.prize > 0) {
            prize_status = false;
            break;
          }
        }

        if(!prize_status) {
            console.log(index);

            this.form.scratch_prices[index] = {prize: "",weight: ""};
            // await this.form.scratch_prices.splice(index, 1);
            Notification.show('Prize already Selected', { type: "error", title: "Error" });
        } else {
            this.form.scratch_prices[index].prize = e;
        }

        console.log(this.form.scratch_prices);

    },
    removeScratchPriceBlock(value) {
      this.form.scratch_prices.splice(value, 1);
    },
  },
  mounted() {
    this.form.game_id = this.gameData.encrypt_id;
    this.form.theme_id = this.themeData.encrypt_id;
  },
  created() {
    const { params } = this.$route;
    if (params?.id) {
      this.isEditing = true;
      this.getDetails();
      this.getScripts();
      this.getPrizes();
      this.scratchPriceCount();
    }
  },
  unmounted() {
    this.$store.commit("setGame", {});
    this.$store.commit("setTheme", {});
  },
};
</script>